import React from "react";

import Footer from "../components/footer";
import HeadContent from "../components/head";
import Header from "../components/header";
import Newsletter from "../components/newsletter";

const PrivacyPage = () => {
  return (
    <>
      <Header />
      <div className="relative overflow-hidden bg-white py-16">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div
            className="relative mx-auto h-full max-w-prose text-lg"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Privacy Policy
              </span>
            </h1>
          </div>
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
            <p>Effective date: 10/17/2022</p>
            <h2>1. Introduction</h2>
            <p>Welcome to UsingTailwind.</p>
            <p>
              UsingTailwind (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)
              operates{" "}
              <a data-fr-linked="true" href="https://usingtailwind.com">
                https://usingtailwind.com
              </a>{" "}
              (hereinafter referred to as &ldquo;Service&rdquo;).
            </p>
            <p>
              Our Privacy Policy governs your visit to{" "}
              <a data-fr-linked="true" href="https://usingtailwind.com">
                https://usingtailwind.com
              </a>
              , and explains how we collect, safeguard and disclose information
              that results from your use of our Service.
            </p>
            <p>
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy.&nbsp;
            </p>
            <p>
              Our Terms and Conditions (&quot;Terms&quot;) govern all use of our
              Service and together with the Privacy Policy constitutes your
              agreement with us (&quot;agreement&quot;).
            </p>
            <h2>2. Definitions</h2>
            <p>
              <strong>SERVICE</strong> means the{" "}
              <a href="https://usingtailwind.com">https://usingtailwind.com</a>{" "}
              website operated by UsingTailwind.
            </p>
            <p>
              <strong>PERSONAL DATA</strong> means data about a living
              individual who can be identified from those data (or from those
              and other information either in our possession or likely to come
              into our possession).
            </p>
            <p>
              <strong>USAGE DATA</strong> is data collected automatically either
              generated by the use of Service or from Service infrastructure
              itself (for example, the duration of a page visit).
            </p>
            <p>
              <strong>COOKIES</strong> are small files stored on your device
              (computer or mobile device).
            </p>
            <p>
              <strong>DATA CONTROLLER</strong> means a natural or legal person
              who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </p>
            <p>
              <strong>DATA PROCESSORS</strong> (OR{" "}
              <strong>SERVICE PROVIDERS</strong>) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
            </p>
            <p>
              <strong>DATA SUBJECT</strong> is any living individual who is the
              subject of Personal Data.
            </p>
            <p>
              <strong>THE USER</strong> is the individual using our Service. The
              User corresponds to the Data Subject, who is the subject of
              Personal Data.
            </p>
            <h2>3. Information Collection and Use</h2>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
            <h2>4. Types of Data Collected</h2>
            <p>
              <strong>Personal Data</strong>
            </p>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (&ldquo;Personal Data&rdquo;). Personally
              identifiable information may include, but is not limited to:
            </p>
            <p>(a) Email address</p>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or by emailing at support[at]usingtailwind.com.
            </p>
            <p>
              <strong>Usage Data</strong>
            </p>
            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (&ldquo;Usage Data&rdquo;).
            </p>
            <p>
              This Usage Data may include information such as your
              computer&apos;s Internet Protocol address(e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>
            <p>
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </p>
            <p>
              <strong>Tracking Cookies Data</strong>
            </p>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information. Cookies
              are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ol>
              <li>
                (a) Session Cookies: We use Session Cookies to operate our
                Service.
              </li>
              <li>
                (b) Preference Cookies: We use Preference Cookies to remember
                your preferences and various settings.
              </li>
              <li>
                (c) Security Cookies: We use Security Cookies for security
                purposes.
              </li>
              <li>
                (d) Advertising Cookies: Advertising Cookies are used to serve
                you with advertisements that may be relevant to you and your
                interests.
              </li>
            </ol>
            <h2>5. Use of Data</h2>
            <p>UsingTailwind uses the collected data for various purposes:</p>
            <ol>
              <li>(a) to provide and maintain our Service;</li>
              <li>(b) to notify you about changes to our Service;</li>
              <li>
                (c) to allow you to participate in interactive features of our
                Service when you choose to do so;
              </li>
              <li>(d) to provide customer support;</li>
              <li>
                (e) to gather analysis or valuable information so that we can
                improve our Service;
              </li>
              <li>(f) to monitor the usage of our Service;</li>
              <li>(g) to detect, prevent and address technical issues;</li>
              <li>
                (h) to fulfill any other purpose for which you provide it;
              </li>
              <li>
                (i) to carry out our obligations and enforce our rights arising
                from any contracts entered into between you and us, including
                for billing and collection;&nbsp;
              </li>
              <li>
                (j) to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li>
                (k) to provide you with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless you have opted not to receive such
                information;
              </li>
              <li>
                (l) in any other way we may describe when you provide the
                information;
              </li>
              <li>(m) for any other purpose with your consent.</li>
            </ol>
            <h2>6. Retention of Data</h2>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>
            <h2>7. Transfer of Data</h2>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>
            <p>
              If you are located outside United States and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to United States and process it there.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              UsingTailwind will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Personal Data will
              take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>
            <h2>8. Disclosure of Data</h2>
            <p>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <ol>
              <li>
                (a) Disclosure for Law Enforcement.
                <ul>
                  <li>
                    Under certain circumstances, we may be required to disclose
                    your Personal Data if required to do so by law or in
                    response to valid requests by public authorities.
                  </li>
                </ul>
              </li>
              <li>
                (b) Business Transaction.
                <ul>
                  <li>
                    If we or our subsidiaries are involved in a merger,
                    acquisition or asset sale, your Personal Data may be
                    transferred.
                  </li>
                </ul>
              </li>
              <li>
                (c) Other cases. We may disclose your information also:
                <ul>
                  <li>(i) to our subsidiaries and affiliates;</li>
                  <li>
                    (ii) to contractors, service providers, and other third
                    parties we use to support our business;
                  </li>
                  <li>
                    (iii) to fulfill the purpose for which you provide it;
                  </li>
                  <li>(iv) with your consent in any other cases;</li>
                </ul>
              </li>
            </ol>
            <h2>9. Security of Data</h2>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <h2>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </h2>
            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR. &ndash; See more at{" "}
              <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </a>
            </p>
            <p>
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </p>
            <p>
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at support[at]usingtailwind.com.
            </p>
            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <ol>
              <li>
                (a) the right to access, update or to delete the information we
                have on you;
              </li>
              <li>
                (b) the right of rectification. You have the right to have your
                information rectified if that information is inaccurate or
                incomplete;
              </li>
              <li>
                (c) the right to object. You have the right to object to our
                processing of your Personal Data;
              </li>
              <li>
                (d) the right of restriction. You have the right to request that
                we restrict the processing of your personal information;
              </li>
              <li>
                (e) the right to data portability. You have the right to be
                provided with a copy of your Personal Data in a structured,
                machine-readable and commonly used format;
              </li>
              <li>
                (f) the right to withdraw consent. You also have the right to
                withdraw your consent at any time where we rely on your consent
                to process your personal information;
              </li>
            </ol>
            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests.
            </p>
            <p>
              Please note, we may not able to provide Service without some
              necessary data.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>
            <h2>
              11. Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </h2>
            <p>
              CalOPPA is the first state law in the nation to require commercial
              websites and online
            </p>
            <p>
              services to post a privacy policy. The law&rsquo;s reach stretches
              well beyond California to require
            </p>
            <p>
              a person or company in the United States (and conceivable the
              world) that operates
            </p>
            <p>
              websites collecting personally identifiable information from
              California consumers to post a
            </p>
            <p>
              conspicuous privacy policy on its website stating exactly the
              information being collected and
            </p>
            <p>
              those individuals with whom it is being shared, and to comply with
              this policy. &ndash; See more at:
            </p>
            <p>
              <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </a>
            </p>
            <p>According to CalOPPA we agree to the following:</p>
            <ol>
              <li>(a) users can visit our site anonymously;</li>
              <li>
                (b) our Privacy Policy link includes the word
                &ldquo;Privacy&rdquo;, and can easily be found on the page
                specified above on the home page of our website;
              </li>
              <li>
                (c) users will be notified of any privacy policy changes on our
                Privacy Policy Page;
              </li>
              <li>
                (d) users are able to change their personal information by
                emailing us at &nbsp;support[at]usingtailwind.com.
              </li>
            </ol>
            <p>Our Policy on &ldquo;Do Not Track&rdquo; Signals:</p>
            <p>
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.
            </p>
            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </p>
            <h2>12. Service Providers</h2>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“<strong>Service Providers</strong>”), provide
              Service on our behalf, perform Service-related services or assist
              us in analysing how our Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
            <h2>13. Analytics</h2>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
            <p>
              <strong>Plausible Analytics</strong>
            </p>
            <p>
              You can find their privacy policy here:{" "}
              <a href="https://plausible.io/privacy">
                https://plausible.io/privacy
              </a>
            </p>
            <h2>14. Payments</h2>
            <p>
              We may provide paid products and/or services within Service. In
              that case, we use third- party services for payment processing
              (e.g. payment processors).
            </p>
            <p>
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.
            </p>
            <p>The payment processors we work with are:</p>
            <p>
              <strong>Stripe</strong>
            </p>
            <p>
              Their Privacy Policy can be viewed at:{" "}
              <a href="https://stripe.com/us/privacy">
                https://stripe.com/us/privacy
              </a>
            </p>
            <h2>15. Links to Other Sites</h2>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party&#39;s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h2>16. Children's Privacy</h2>
            <p>
              Our Services are not intended for use by children under the age of
              13 (“<strong>Children”</strong>).
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from Children under 13. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </p>
            <h2>17. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h2>18. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email: support[at]usingtailwind.com.
            </p>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default PrivacyPage;

export const Head = ({ location }) => (
  <HeadContent title="Privacy Policy - Using Tailwind" location={location} />
);
